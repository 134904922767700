<template>
    <div>
        <h1>All Announcements</h1>

        <div v-if="isTableLoading" class="text-center pt-3">
            <b-spinner label="Loading..." />
        </div>

        <template v-else>

            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <b-button variant="primary" class="float-right" v-b-modal.manage-modal @click="openAddModal">
                        <span> Add Announcement </span>
                    </b-button>
                </b-form-group>
            </div>

            <vue-good-table
                    :columns="columns"
                    :rows="announcements"
                    :search-options="{
                      enabled: true
                    }"
                    :pagination-options="{
                      enabled: true,
                      perPage: pageLength,
                    }"
                >

                <template slot="table-column" slot-scope="props">
                    <span>
                        {{props.column.label}}
                    </span>
                </template>

                <template slot="table-row" slot-scope="props">
                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                        <span>
                            <b-dropdown
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    right
                            >
                                <template v-slot:button-content>
                                  <feather-icon
                                          icon="MoreVerticalIcon"
                                          size="16"
                                          class="text-body align-middle mr-25"
                                  />
                                </template>
                                <b-dropdown-item
                                        v-if="props.row.status !== 6"
                                        v-b-modal.manage-modal
                                        @click="openEditModal(props.row)"
                                >
                                    <feather-icon icon="Edit2Icon" class="mr-50" />
                                    <span>Edit</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="deleteItem(props.row.id)">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-end flex-wrap">
                        <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </template>
            </vue-good-table>
        </template>


        <b-modal id="manage-modal" :title="edited.id == 0 ? 'Add new announcement' : 'Edit announcement details'">
            <validation-observer ref="createValidation">
            <b-form>

                <b-form-group label="Title:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="title" rules="required">
                        <b-form-input v-model="edited.title" placeholder="Announcement Name" required />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Announcement:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="announcement" rules="required">
                        <b-form-textarea type="text" v-model="edited.announcement" placeholder="Announcement Price" required />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="From date:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="from_date" rules="required">
                        <b-form-datepicker
                            id="from_date"
                            v-model="edited.from_date"
                            locale="en"
                            required
                            @context="onContext"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <b-form-group label="Expire date:" label-for="input-name">
                    <validation-provider #default="{ errors }" name="expire_date" rules="required">
                        <b-form-datepicker
                            id="expire_date"
                            v-model="edited.expire_date"
                            locale="en"
                            required
                            @context="onContext"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

            </b-form>
            </validation-observer>

            <template #modal-footer>
                <b-button variant="outline-secondary" class="float-right" @click="$bvModal.hide('manage-modal')">
                    Close
                </b-button>
                <b-button variant="primary" class="float-right" @click="edited.id == 0 ? addSubmit(): editSubmit()" :disabled="isEditLoading">
                    <b-spinner v-if="isEditLoading" label="Spinning" small />
                    <span v-else> Save </span>
                </b-button>
            </template>

        </b-modal>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required } from '@validations'
    import {
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BDropdown,
        BDropdownItem,
        VBModal,
        BForm,
        BFormDatepicker,
        BSpinner,
        BButton,
        BFormCheckbox,
        BTable,
        VBTooltip,
    } from "bootstrap-vue";
    import vSelect from "vue-select";
    import { VueGoodTable } from "vue-good-table";
    import { mapActions, mapGetters } from "vuex";
    import axios from "@/libs/axios";
    import { toastSuccess, toastFailure } from "@/libs/toastification";
    import {sweetWarning, sweetError} from "@/libs/sweet-alerts";

    export default {
        components: {
            VueGoodTable,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BDropdown,
            BDropdownItem,
            BFormTextarea,
            BForm,
            vSelect,
            ValidationProvider,
            ValidationObserver,
            BFormDatepicker,
            BSpinner,
            BButton,
            BFormCheckbox,
            BTable,
        },
        directives: {
            "b-modal": VBModal,
            "b-tooltip": VBTooltip,
        },
        data() {
            return {
                isTableLoading: true,
                isEditLoading: false,
                columns: [

                    {
                        label: "Title",
                        field: "title",
                        type: "string",
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "From Date",
                        field: "from_date",
                        type: 'date',
                        dateInputFormat: 'yyyy-MM-dd',
                        dateOutputFormat: 'MMM do yyyy',
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Expire Date",
                        field: "expire_date",
                        type: 'date',
                        dateInputFormat: 'yyyy-MM-dd',
                        dateOutputFormat: 'MMM do yyyy',
                        filterOptions: {
                            enabled: true,
                            placeholder: "All",
                            trigger: "enter",
                        },
                    },
                    {
                        label: "Action",
                        field: "action",
                        thClass: "text-right pr-1",
                        tdClass: "text-right",
                        sortable: false,
                    },
                ],
                pageLength: 10,
                edited: {
                    id: null,
                    user_id: null,
                    for_role_id: null,
                    title: null,
                    announcement: null,
                    from_date: null,
                    expire_date: null
                }
            };
        },
        computed: {
            ...mapGetters({
                announcements: "announcements/announcements"
            })
        },
        watch: {
            ////
        },
        async mounted() {

            await this.loadAnnouncements()
                .then(()=>{

                })
                .catch(() => {
                    toastFailure('Something went wrong.')
                });
            this.isTableLoading = false;
        },
        methods: {
            ...mapActions({
                loadAnnouncements: "announcements/loadAnnouncements",
                updateAnnouncement: "announcements/updateAnnouncement",
                deleteAnnouncement: "announcements/deleteAnnouncement",
                addAnnouncement: "announcements/addAnnouncement"
            }),
            onContext(ctx) {
                this.formatted = ctx.selectedFormatted;
                this.selected = ctx.selectedYMD;
            },
            showError(responseData=null, warning=false) {

                if( responseData != null && responseData.message != undefined && responseData.message != null ){

                    let errorMSG = responseData.message + '</br>';
                    if(responseData.errors){

                        Object.keys(responseData.errors).map(function(errorKey){
                            responseData.errors[errorKey].map(function(value, key){
                                errorMSG += value + '</br>';
                            });
                        });
                    }

                    if( warning ){
                        sweetWarning('Warning', errorMSG);
                    }
                    else{
                        sweetError('Error', errorMSG);
                    }
                }
                else{
                    toastFailure("Something went wrong.");
                }
            },
            openEditModal(row) {
                this.edited.id = row.id;
                this.edited.user_id = row.user_id;
                this.edited.for_role_id = row.for_role_id;
                this.edited.title = row.title;
                this.edited.announcement = row.announcement;
                this.edited.from_date = row.from_date;
                this.edited.expire_date = row.expire_date;
            },
            openAddModal() {
                this.edited.id = 0;
                this.edited.user_id = 0;
                this.edited.for_role_id = 3;
                this.edited.title = '';
                this.edited.announcement = '';
                this.edited.from_date = '';
                this.edited.expire_date = '';
            },

            async deleteItem(id) {
                if (await this.confirmationMessage('Are you sure you want to delete this announcement?')) {
                    this.deleteAnnouncement(id)
                        .then(() => {
                            toastSuccess("Announcement has been deleted.");
                        })
                        .catch(() => {
                            toastFailure("Something went wrong while deleting the announcement");
                        });
                }
            },
            async addSubmit() {

                this.isEditLoading = true;
                this.addAnnouncement({
                    title: this.edited.title,
                    announcement: this.edited.announcement,
                    from_date: this.edited.from_date,
                    expire_date: this.edited.expire_date,
                    for_role_id: this.edited.for_role_id
                })
                    .then(() => {
                        toastSuccess("New announcement added.");
                    })
                    .catch((error) => {
                        toastFailure("Something went wrong while adding a new announcement.");
                    })
                    .finally(() => {
                        this.isEditLoading = false;
                        this.$bvModal.hide('manage-modal');
                    });
            },
            async editSubmit() {

                this.isEditLoading = true;
                this.updateAnnouncement({
                    id: this.edited.id,
                    title: this.edited.title,
                    announcement: this.edited.announcement,
                    from_date: this.edited.from_date,
                    expire_date: this.edited.expire_date
                })
                    .then(() => {
                        toastSuccess("Your changes have been updated.");
                    })
                    .catch((error) => {
                        toastFailure("Something went wrong while updating the announcement.");
                    })
                    .finally(() => {
                        this.isEditLoading = false;
                        this.$bvModal.hide('manage-modal');
                    });
            },

            onColumnFilter(params) {
                this.changeUrl(params.columnFilters);
            },
            changeUrl(values) {
                const searchParams = new URLSearchParams(window.location.search);

                /* eslint-disable-next-line */
                for (const [key, value] of Object.entries(values)) {
                    searchParams.set(key, value);
                }

                window.history.pushState(
                    {},
                    null,
                    `${this.$route.path}?${searchParams.toString()}`
                );
            },

            confirmationMessage(message) {
                return this.$bvModal.msgBoxConfirm(message, {
                    title: 'Please Confirm',
                    okVariant: 'primary',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                });
            }
        },
    };
</script>

<style>
</style>
